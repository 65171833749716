import LogRocket from 'logrocket'
import LogRocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer'
import { PropsWithChildren } from 'react'
import { useEffect } from 'react'

import { useAdminContext } from '~/components/hooks/useAdminContext'
import { useCurrentUser } from '~/components/hooks/useCurrentUser'
import { useFirm } from '~/components/hooks/useFirm'
import { isProd } from '~/util/env'
import { FIRM_SIDS_TO_EXCLUDE_FROM_SESSION_LOGGING } from '~/util/logging'

export function LogRocketWrapper({ children }: PropsWithChildren) {
  const user = useCurrentUser()
  const firm = useFirm()
  const { isImpersonating } = useAdminContext()
  useEffect(
    function initLogRocket() {
      if (
        isProd &&
        !isImpersonating &&
        firm &&
        !FIRM_SIDS_TO_EXCLUDE_FROM_SESSION_LOGGING.includes(firm.sid)
      ) {
        const SENSITIVE_HEADERS = [
          'Authorization',
          'Cookie',
          'Set-Cookie',
          'X-CSRF-Token',
          'X-XSRF-Token',
          'Session',
          'X-API-Key',
          'Bearer',
          'access_token',
          'refresh_token',
          'id_token',
          'token',
          'sessionid',
          'x-auth-token',
        ]

        const { requestSanitizer, responseSanitizer } =
          LogRocketFuzzySanitizer.setup(SENSITIVE_HEADERS)

        LogRocket.init('lhdfyv/prod', {
          network: {
            requestSanitizer: request => {
              const requestWithBody = { ...request, body: request.body ?? null }
              return requestSanitizer(requestWithBody)
            },
            responseSanitizer: response => {
              const responseWithBody = { ...response, body: response.body ?? null }
              return responseSanitizer(responseWithBody)
            },
          },
        })
        LogRocket.identify(user.sid, {
          name: user.fullName,
          email: user.email,
          role: user.role,
          firmSid: firm.sid,
          firmName: firm.name,
        })
      }
    },
    [firm, user.email, user.fullName, user.role, user.sid, isImpersonating]
  )
  return <>{children}</>
}
